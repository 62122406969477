<template>
    <v-app-bar :style="{background: $vuetify.theme.themes[theme].background,color:$vuetify.theme.themes[theme].color}"  app>
        <v-app-bar-nav-icon @click="$store.state.drawer = !$store.state.drawer" dark><v-icon>menu</v-icon></v-app-bar-nav-icon>
        <v-toolbar-title>
            {{ PageTitle }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
            <v-text-field
                    @focus="searchClosed = false"
                    @blur="searchClosed = true"
                    v-model="search"
                    append-icon="search"
                    class="expanding-search mt-1"
                    :class="{ 'closed': searchClosed && !search }"
                    placeholder="Ara..."
                    color="white"
                    dark
                    dense
                    clearable
            />

        <v-btn icon @click="ChangeTheme" dark>
            <v-icon>wb_sunny</v-icon>
        </v-btn>


        <v-btn icon dark>
            <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-width="120"
                    offset-x
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar color="red" size="24" v-bind="attrs" v-on="on">
                        <span class="white--text text-h8">Ö</span>
                    </v-avatar>
                </template>

                <v-card>
                    <v-list>
                        <v-list-item>
                            <v-list-item-avatar>
                                <img
                                        src="/assets/images/ozkan.png"
                                        alt="Özkan G."
                                >
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>Özkan Güner</v-list-item-title>
                                <v-list-item-subtitle>MooWifi</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-list dense>
                        <v-list-item-group>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>manage_accounts</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Hesap ayarları.</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>logout</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content @click="logout">
                                <v-list-item-title>Güvenli Çıkış.</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-menu>
        </v-btn>
    </v-app-bar>
</template>

<script>
    export default {
        name: "toolbar",
        data() {

            return {
                selectedItem: 0,
                searchClosed:true,
                mini: true,
                menu:false,
                initialDark: this.$vuetify ? this.$vuetify.theme.dark : false,
                items: [
                    { text: 'Dashboard', icon: 'dashboard', link: "dashboard" },
                    { text: 'HotSpot Listesi', icon: 'settings_input_antenna', link: "hotspot" },
                    { text: 'İşletmeler', icon: 'store', link: "business" },
                    { text: 'Kullanıcılar', icon: 'person', link: "users" },
                    { text: 'Güvenli Çıkış', icon: 'logout', link: "logout" }
                ],
            }
        },
        methods:{
            logout(){
                this.$store.commit("setAuth",false)
                this.$store.commit("setAdmin",false)
                this.$router.push({name:"Login"})
            },
          ChangeTheme(){
              this.$vuetify.theme.dark=!this.$vuetify.theme.dark
              if(this.$vuetify.theme.dark){
                  localStorage.setItem("theme","dark")
              }else{
                  localStorage.setItem("theme","light")
              }
          }
        },
        computed: {
            theme(){
                return (this.$vuetify.theme.dark) ? 'dark' : 'light'
            },
            logoImg(){
                return (this.$vuetify.theme.dark) ? '/assets/images/logo_light.png' : '/assets/images/logo_dark.png'
            },
            PageTitle:{
                get(){
                    return this.$store.state.PageTitle
                },
                set(val){
                    this.$store.commit("setPageTitle",val);
                }
            },
            search:{
                get(){
                    return this.$store.state.search
                },
                set(val){
                    this.$store.commit("setSearch",val)
                }
            }
        }
    }
</script>

<style lang="sass" scoped>
    .v-input.expanding-search
        transition: all 0.3s
        max-width: 240px
        padding-top: 15px
        cursor: pointer
        .v-input__slot
            &:before, &:after
                border-color: transparent !important
        &.closed
            margin-right: 65px
            max-width: 0px
            padding-top: 15px
            cursor: pointer
            .v-input__slot
                background:  transparent !important
            &:before, &:after
                border: none
</style>