<template>
    <v-navigation-drawer
            v-model="drawer"
            app
            id="moowifi"
            :style="{backgroundColor: $vuetify.theme.themes[theme].leftbar,color:$vuetify.theme.themes[theme].color}"
            :dark="$vuetify.theme.dark"
    >
        <v-list-item>
            <v-img contain :src="logoImg" max-height="64px"></v-img>
        </v-list-item>

        <v-divider></v-divider>
        <v-list dense nav>
            <div v-for="item in items" :key="item.title">

            <v-list-group
                    v-model="item.active"
                    :prepend-icon="item.action"
                    v-if="item.items!=false"
            >
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item
                        v-for="child in item.items"
                        :key="child.title"
                >
                    <v-list-item :to="child.link">
                        <v-list-item-icon>
                            <v-icon v-text="child.icon"></v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title v-text="child.title"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </v-list-item>

            </v-list-group>
            <v-list-item-group v-model="item.active" color="primary" v-else>
                <v-list-item :to="item.link">
                    <v-list-item-icon>
                        <v-icon v-text="item.action"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title  v-text="item.title"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action v-if="item.plus_event">
                        <v-btn

                                fab
                                dark
                                color="primary"
                                @click="CallFN(item.plus_event)"
                                x-small
                        >
                            <v-icon dark>
                                add
                            </v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list-item-group>
            </div>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    export default {
        name: "sidebar",
        data() {
            return {
                search:'',
                selectedItem: 0,
                mini: true,
                initialDark: this.$vuetify ? this.$vuetify.theme.dark : false,
                items2: [
                    { text: 'Dashboard', icon: 'dashboard', link: "dashboard" },
                    { text: 'HotSpot Listesi', icon: 'settings_input_antenna', link: "hotspot" },
                    { text: 'İşletmeler', icon: 'store', link: "business" },
                    { text: 'Kullanıcılar', icon: 'person', link: "users" },
                    { text: 'Güvenli Çıkış', icon: 'logout', link: "logout" }
                ],
                items: [
                    { action: 'dashboard', items: false, title: 'Dashboard', link: 'dashboard', plus_event: false },
                    { action: 'settings_input_antenna', items: false, title: 'HotSpot Listesi', link: 'hotspot', plus_event: 'AddHotSpot' },
                    { action: 'store', items: false, title: 'İşletmeler', link: 'business', plus_event: 'AddBusiness' },
                    { action: 'person', items: false, title: 'Kullanıcılar', link: 'users', plus_event: 'AddUsers' },
                    { action: 'group', items: false, title: 'HS Müşteriler', link: 'hotspot-users' },
                    // {
                    //     action: 'settings_input_antenna',
                    //     items: [
                    //         {
                    //             title: 'Yeni HotSpot',
                    //             icon: 'router',
                    //             link: 'hotspot',
                    //         },
                    //         {
                    //             title: 'HotSpot Listesi',
                    //             icon: 'router',
                    //             link: 'business',
                    //         },
                    //         {
                    //             title: 'Merkezler',
                    //             icon: 'wifi_tethering',
                    //             link: 'users',
                    //         }
                    //
                    //         ],
                    //     title: 'HotSpot',
                    // },
                ],
            }
        },
        methods: {
          CallFN(callback){
              this[callback]();
          },
            AddHotSpot(){
                this.$store.commit("ShowCreateHotSpotDialog",true)
                return false;
            },
            AddBusiness(){
              alert("test");
            },
            AddUsers(){
              alert("test");
            },
        },
        computed: {
            theme(){
                return (this.$vuetify.theme.dark) ? 'dark' : 'light'
            },
            logoImg(){
                return (this.$vuetify.theme.dark) ? '/assets/images/logo_light.png' : '/assets/images/logo_dark.png'
            },
            drawer:{
                get(){
                    return this.$store.state.drawer
                },
                set(val){
                    this.$store.commit("setDrawer",val);
                }
            }
        }
    }
</script>

<style scoped>
    #moowifi {
        background-image: url("/assets/images/bg-stil.svg");
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: bottom left;
        background-size: 260px 260px;
    }
    .v-list-item__icon {
        align-self: center;
    }
    .v-list-item__action {
        margin:0;
    }
</style>