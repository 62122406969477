<template>
    <v-dialog v-model="dialog" max-width="900px">
        <v-card style="min-height: 530px;" v-if="connectionError==null">
            <v-card-title>
                <h3  v-if="total>0">{{ title }} </h3>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="search" label="Ara..." single-line hide-details style="padding-right: 1em;max-width: 220px" v-if="total>0"></v-text-field>
            </v-card-title>
            <v-card-subtitle v-if="total>0"><span> Toplam : <b>{{ total }}</b> kişi eklendi..</span></v-card-subtitle>
            <v-data-table dense :headers="header" :items="data" :search="search" :itemsPerPage="15" v-if="total>0">
                <template slot="items" slot-scope="props">
                    <tr v-if="!isMobile">
                        <td>{{ props.item.firstname }}</td>
                        <td class="text-xs-left">{{ props.item.lastname }}</td>
                        <td class="text-xs-left">{{ props.item.identify }}</td>
                        <td class="text-xs-left">{{ props.item.country }}</td>
                        <td class="text-xs-left">{{ props.item.room }}</td>
                    </tr>
                </template>
            </v-data-table>
            <div v-else>
                <h3 class="text-center">{{ title }} {{ data }}</h3>
            </div>
        </v-card>
        <v-card v-else>
            <v-card-title>
                <h3>{{ title }} </h3>
            </v-card-title>
            <v-card-subtitle><span>{{ ErrorMassege }}</span></v-card-subtitle>
            <div style="padding: 1.5rem" v-html="connectionError"></div>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "new-users",
        data(){
            return {
                search:'',
                header: [
                    {
                        text: 'Isim',
                        align: 'left',
                        value: 'firstname'
                    },
                    {
                        text: 'Soyisim',
                        value: 'lastname'
                    },
                    {
                        text: 'Kimlikno',
                        value: 'identify'
                    },
                    {
                        text: 'Ülke',
                        value: 'country'
                    },
                    {
                        text: 'Oda',
                        value: 'room'
                    },
                ],
            }
        },
        computed: {
            dialog:{
                get(){
                    return this.$store.state.refreshplace.dialog
                },
                set(){
                    this.$store.commit("refreshplace/ShowDialog",!this.dialog)
                }
            },
            total(){
                 return this.$store.state.refreshplace.total
            },
            title(){
                 return this.$store.state.refreshplace.title
            },
            data(){
                 return this.$store.state.refreshplace.data
            },
            connectionError(){
                 return this.$store.state.refreshplace.connectionError
            },
            ErrorMassege(){
                 return this.$store.state.refreshplace.ErrorMessage
            },

        },
    }
</script>

<style scoped>
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
        font-size: 0.8rem;
        font-weight: 400;
    }
    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
        height: 22px;
        padding: 1px 12px;
    }
</style>