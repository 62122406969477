<template>
    <v-app>
        <sidebar />
        <toolbar />
        <v-main>
            <v-container class="pa-4" fluid="fluid" grid-list-md="grid-list-md">
                 <router-view />
            </v-container>
        </v-main>
        <new-hot-spot />
        <inhouse />
        <loading />
        <registered-users />
        <new-users />
    </v-app>
</template>

<script>
import Sidebar from "../components/layout/sidebar";
import Toolbar from "../components/layout/toolbar";
import NewHotSpot from '../components/hotspot/new-hotspot'
import Inhouse from "../components/hotspot/inhouse";
import Loading from "../components/layout/loading";
import RegisteredUsers from "../components/hotspot/registered-users";
import NewUsers from "../components/hotspot/new-users";
export default {
    components: {NewUsers, RegisteredUsers, Loading, Inhouse, Toolbar, Sidebar, NewHotSpot},
    name: 'MainLayout',
}
</script>
<style>
    .application {
        line-height:1.5;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
        font-size: 0.8rem;
        font-weight: 400;
    }
    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
        height: 22px;
        padding: 1px 12px;
    }
    .theme--dark.v-icon.v-icon.v-icon--disabled {
        color: rgba(102 ,108, 150 , 0.52) !important;
    }
</style>
