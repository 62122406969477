<template>
    <v-dialog
            v-model="loading"
            hide-overlay
            persistent
            width="300"
    >
        <v-card
                color="primary"
                dark
        >
            <v-card-text>
                Database bağlantısı sağlanıyor..
                <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                ></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "loading",
        computed: {
            loading() {
                  return this.$store.state.loading
            }
        }
    }
</script>

<style scoped>

</style>