<template>
    <v-dialog v-model="HotSpotCreate" max-width="900px">
        <v-card style="padding: 5px;">
            <v-card-actions>
                <v-form ref="form" v-model="valid" lazy-validation style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: space-between;">
                    <div style="width: 100%;margin: 20px 0">
                        <h3>İşletme Bilgileri</h3>
                        <hr />
                    </div>
                    <v-autocomplete v-model="PlaceConfig.PlaceInfo" :items="PlaceInfoData" outlined dense chips small-chips item-text="P_Name" label="İşletme Seçin *" return-object :rules="autoRules" required></v-autocomplete>
                    <ul v-if="PlaceConfig.PlaceInfo.P_ContractPackage" style="list-style-type: none">
                        <li><b>Adres :</b> {{ PlaceConfig.PlaceInfo.P_Address }}</li>
                        <li><b>Yetkili :</b> {{ PlaceConfig.PlaceInfo.P_Authorized }}</li>
                        <li><b>Telefon :</b> {{ PlaceConfig.PlaceInfo.P_AutPhone }}</li>
                        <li><b>Paket :</b> {{ PlaceConfig.PlaceInfo.P_ContractPackage }}</li>
                        <li><b>Fiyat :</b> {{ PlaceConfig.PlaceInfo.P_ContractPrice }}</li>
                    </ul>
                    <div style="width: 100%;margin: 20px 0">
                        <h3>Vertabanı Bağlantı Bilgileri</h3>
                        <hr />
                    </div>
                    <v-text-field v-model="PlaceConfig.P_Host" label="IP Adres" style="margin-right: 15px;width: 180px" required></v-text-field>
                    <v-text-field v-model="PlaceConfig.P_Port" label="Port" style="margin-right: 15px;width: 180px" required></v-text-field>
                    <v-text-field v-model="PlaceConfig.P_Username" label="Kullanıcı adı" style="margin-right: 15px;width: 180px" required></v-text-field>
                    <v-text-field v-model="PlaceConfig.P_Password" label="Şifre" style="margin-right: 15px;width: 180px" required></v-text-field>
                    <v-text-field v-model="PlaceConfig.P_DB" label="Database Adı" style="margin-right: 15px;width: 180px" required></v-text-field>
                    <v-select v-model="PlaceConfig.P_DB_Brand" :items="Program" style="margin-right: 15px;width: 180px" label="Program"></v-select>
                    <div v-if="PlaceConfig.P_DB_Brand=='API MooWifi'" style="width: 100%;margin: 20px 0">
                        <v-textarea label="API KEY" value="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJob3N0IjoiY2xvdWQuYnV0aWtzb2Z0LmNvbSJ9.VjFSS1ZrMVZPVlZUYld4UFZqRlZNRmRVU2t0aVJteHhWVmhrVG1GdFRqTlhWbVJHVFVVeE5sVlljRTVXUlVWNVZERlNSbVZyTVhGWFZEQTk="></v-textarea>
                    </div>
                    <div style="width: 100%;margin: 20px 0">
                        <h3>HotSpot Ayarları</h3>
                        <hr />
                    </div>
                    <v-checkbox v-model="HotSpotType.hotel" color="primary" style="margin-right: 15px;width: 180px" label="Hotel"></v-checkbox>
                    <v-checkbox v-model="HotSpotType.sms" color="primary" style="margin-right: 15px;width: 180px" label="SMS"></v-checkbox>
                    <v-checkbox v-model="HotSpotType.email" color="primary" style="margin-right: 15px;width: 180px" label="Email"></v-checkbox>
                    <v-text-field v-model="PlaceConfig.P_Ip" label="HS Interface" style="margin-right: 15px;width: 180px" required></v-text-field>
                    <v-text-field v-model="PlaceConfig.P_Logo" label="Logo Dosyası" style="margin-right: 15px;width: 180px" required></v-text-field>
                    <v-textarea label="NOT" v-model="PlaceConfig.Note"></v-textarea>
                    <p style="color: red;font-weight: 700">{{ kayiterror }}</p>
                    <v-btn :disabled="!valid" style="width:98%;" color="primary" class="mr-4" @click="ConfigCreate">KAYDET</v-btn>
                </v-form>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "new",
        data(){
            return {
                kayit2:false,
                PlaceConfig: {
                    PlaceInfo     :     [],
                    P_Host        :     "" ,
                    P_Port        :     "" ,
                    P_Username    :     "" ,
                    P_Password    :     "" ,
                    P_DB          :     "" ,
                    P_LoginTypes  :     "null" ,
                    P_Logo        :     "moowifi.png" ,
                    P_Header      :     "" ,
                    P_Ip          :     "" ,
                    P_DB_Brand    :     "" ,
                    Note          :     "" ,
                    Service       :     ""
                },
                Program: ['MooWifi','SistemOtel','Opera','Elektra','API MooWifi'],
                HotSpotType: {
                    hotel: true,
                    sms:true,
                    email:true
                },
                autoRules: [
                    v => !!v || 'İşletme seçiniz..',
                    v => (v.P_Id > 0) || 'Lütfen bir işletme seçiniz.',
                ],
                nameRules: [
                    v => !!v || 'Bu alanı doldurmalısınız..',
                    v => (v && v.length >= 5) || 'En az 5 karakter uzunluğunda olmalı.',
                ],
                passRules: [
                    v => !!v || 'Bu alanı doldurmalısınız..',
                    v => (v && v.length >= 8) || 'Şifre en az 8 karakter olmalı',
                ],
                email: '',
                emailRules: [
                    v => !!v || 'E-posta adresi zorunludur.',
                    v => /.+@.+\..+/.test(v) || 'E-posta adresi geçersiz.',
                ],
                numberRule: [
                    v  =>  !!v || 'Lütfen tam sayı giriniz..',
                    v  =>  !isNaN(parseFloat(v)) && v >= 0 && v <= 999999 || 'Lütfen Tam sayı giriniz..',
                ],
                valid:false,
                kayiterror:'',

            }


        },
        methods: {
            AddConnection(){
                this.kayit2 = true
            },
            LoginTypeSet(){
                let hotel=0;
                let sms=0;
                let mail=0;
                if(this.HotSpotType.hotel){
                    hotel = 1;
                }else{
                    hotel = 0;
                }
                if (this.HotSpotType.sms){
                    sms = 1;
                }else{
                    sms = 0;
                }
                if (this.HotSpotType.email){
                    mail = 1;
                }else {
                    mail = 0;
                }
                return hotel + "" + sms + "" + mail + "1"
            },

            ConfigCreate(){
                if(!this.$refs.form.validate()){
                    return false
                }
                this.dialog = true
                this.PlaceConfig.P_LoginTypes = this.LoginTypeSet()
                this.PlaceConfig.P_Id = this.PlaceConfig.PlaceInfo.P_Id
                this.PlaceConfig.P_Header = this.PlaceConfig.PlaceInfo.P_Name
                this.PlaceConfig.Service ="VjFSS1ZrMVZPVlZUYld4UFZqRlZNRmRVU2t0aVJteHhWVmhrVG1GdFRqTlhWbVJHVFVVeE5sVlljRTVXUlVWNVZERlNSbVZyTVhGWFZEQTk="
                var place_detail = this.PlaceConfig
                this.returnMessage = this.$store.hotspot.dispatch("HotSpotCreate",place_detail);
                this.dialog = false
                this.kayit = false
                this.returnStatus = true
            },
        },
        computed: {
            HotSpotCreate:{
                get(){
                    return this.$store.state.hotspot.createDialog
                },
                set(){
                    this.$store.commit("ShowCreateHotSpotDialog",!this.$store.state.hotspot.createDialog)
                }
            },
            PlaceInfoData(){
                return this.$store.state.business.data
            }
        },
        created() {
            if(this.PlaceInfoData.length<1){
                this.$store.dispatch("BusinessListUpdate")
            }
        }
    }
</script>

<style scoped>

</style>